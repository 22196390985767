/* purgecss start ignore */
#intro {
  position: relative;


  @include media-breakpoint-up(xl) {
	margin: 0 auto;
	padding: 75px 0;
	width: 1150px;
  }

  .swipers {
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(md) {
	  flex-direction: row;
	}

	#swiper-intro-text {
	  width: 90%;
	  margin: -45px auto 0 auto;
	  order: 2;
	  background: #000;
	  color: #FFF;
	  padding: 20px;
	  position: relative;
	  z-index: 2;

	  .swiper {
		height: 100%;
	  }

	  @include media-breakpoint-up(md) {
		margin: 0 auto;
		min-height: 400px;
		padding: 40px;
		width: 50%;
		order: 1;
	  }

	  @include media-breakpoint-up(lg) {
		height: 60vh;
		width: 40%;
	  }

	  .swiper-wrapper {
		align-items: center;
	  }

	  .swiper-slide {
		height: 100%;
		display: flex;
		flex-direction: column;
		//height: auto;
		justify-content: center;
		@include media-breakpoint-up(md) {
		  // height: 320px;
		}

		.logo {
		  img {
			height: auto;
		  }

		  margin-bottom: 2.5rem;
		}

		h1, h2, p, .logo {
		  transition: opacity 1s ease;
		  opacity: 0;

		}


		&.swiper-slide-active, &.swiper-slide-duplicate-active {
		  h1, h2, p, .logo {
			transition: opacity 1s ease 1s;
			opacity: 1;
		  }
		}
	  }


	  .angle {
		height: 100%;
		width: auto;
		position: absolute;
		top: 0;
		right: 1px;
		left: auto;
		transform: translate(100%, 0);
		z-index: 2;
		display: none;

		@include media-breakpoint-up(md) {
		  display: block;
		}


	  }


	}

	#swiper-intro-visuel {
	  width: 100%;
	  height: 300px;
	  order: 1;

	  @include media-breakpoint-up(md) {
		width: 50%;
		order: 2;
		min-height: 400px;
	  }

	  @include media-breakpoint-up(lg) {
		height: 60vh;
		width: 60%;
	  }
	  @include media-breakpoint-up(xl) {
		padding: 20px 0;
	  }


	  .swiper {
		height: 100%;
	  }

	  .swiper-slide {
		transition: opacity 0.5s ease;
		height: auto;
		background-size: cover;
		background-position: center;
	  }

	  .bg-presentation {
		background-image: url("../images/facade-2.jpg");
	  }

	  .bg-pause-midi {
		background-image: url("../images/carte.jpg");
		@include media-breakpoint-up(md) {
		  background-image: url("../images/carte-tablette.jpg");
		}
		@include media-breakpoint-up(lg) {
		  background-image: url("../images/carte.jpg");
		}
	  }

	  .bg-pneus-hiver {
		background-image: url("../images/snow-tire.jpg");
	  }

	  .bg-gardiennage {
		background-image: url("../images/gardiennage.jpg");
	  }
	}


  }

  .swiper-pagination {
	position: relative;
	padding: 30px 0;

	@include media-breakpoint-up(md) {
	  position: absolute;
	  width: 50%;
	}
	@include media-breakpoint-up(lg) {
	  width: 40%;
	}
	@include media-breakpoint-up(xl) {
	  bottom: 80px
	}


	.swiper-pagination-bullet {
	  opacity: 1;
	  background: #000;
	  width: 15px;
	  height: 15px;
	  @include media-breakpoint-up(md) {
		background: #FFF;
	  }

	  &.swiper-pagination-bullet-active {
		background: $primary;
	  }
	}
  }
}

/* purgecss end ignore */
