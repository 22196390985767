#prestations {
  position: relative;


  @include media-breakpoint-up(md) {
	.title-fat {
	  margin-bottom: 0;
	}
  }


  &::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 40%;
	max-height: 100vh;
	left: 0;
	right: 0;
	background-color: $primary;
	background-image: url("../images/trace-pneu.png");
	background-size: auto 100%;
	background-position: center;
	background-repeat: no-repeat;

	@include media-breakpoint-up(md) {
	  bottom: 15%;
	  max-height: 70vh;
	}
  }

  .bloc-contenu {
	z-index: 2;
	position: relative;
	max-width: 950px;
	margin: 0 auto;
  }

  .intro {

	@include media-breakpoint-up(md) {
	  display: flex;
	  align-items: flex-end;
	  justify-content: space-between;
	}


	.left {
	  .pneu-logo {
		margin-top: 40px;

		&:after {
		  background-image: url("../images/pneu-logo-gray.svg");
		  transform: translate(0, 0);
		  left: 0;
		  opacity: .12;
		}
	  }
	}

	.right {
	  .btn-contact {
		display: inline-block;
		padding: 5px 10px;
		border: 1px solid #FFF;
		background: transparent;
		color: #FFF;
		font-weight: 700;
	  }
	}
  }

  .content {
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;

	article {
	  width: 100%;
	  background: #fcfcfc;
	  padding: 35px 20px;

	  &:nth-child(even) {
		background: $gray;
	  }

	  display: flex;
	  flex-wrap: wrap;
	  align-items: center;
	  @include media-breakpoint-up(md) {
		display: block;
	  }


	  @include media-breakpoint-up(md) {
		width: 50%;
		&:nth-child(1), &:nth-child(4), &:nth-child(5), &:nth-child(8) {
		  background: #fcfcfc;
		}
		&:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7) {
		  background: $gray;
		}
	  }

	  @include media-breakpoint-up(lg) {
		width: 25%;
		&:nth-child(1), &:nth-child(3), &:nth-child(6), &:nth-child(8) {
		  background: #fcfcfc;
		}
		&:nth-child(2), &:nth-child(4), &:nth-child(5), &:nth-child(7) {
		  background: $gray;
		}
	  }


	  .icon {
		flex: 0 0 65px;
		width: 65px;

		@include media-breakpoint-up(md) {
		  margin-bottom: 35px;
		  width: auto;
		  flex: none;
		}

		svg {
		  width: 50px;
		  height: 50px;
		  fill: $primary;

		  path {
			fill: $primary;
		  }
		}
	  }

	  h3 {
		font-weight: 700;
		font-size: 1.3rem;
		//margin-bottom: 1rem;
		margin-bottom: 0;
		text-transform: uppercase;
		position: relative;

		flex: 0 0 calc(100% - 65px);
		width: calc(100% - 65px);

		@include media-breakpoint-up(md) {
		  margin-bottom: 1rem;
		  width: auto;
		  flex: none;
		}


		&:after {
		  content: '';
		  vertical-align: middle;
		  display: inline-block;
		  margin-left: 10px;
		  width: 20px;
		  height: 20px;
		  background-image: url("../images/down.svg");
		  background-size: cover;
		  @include media-breakpoint-up(md) {
			display: none;
		  }

		}
	  }

	  p {
		flex: 0 0 100%;
		max-height: 0;
		overflow: hidden;
		//padding: 0 1em;
		transition: all .5s;

		@include media-breakpoint-up(md) {
		  max-height: 100vh;
		}

	  }

	  &.open {
		h3::after {
		  transform: rotate(180deg);
		}

		p {

		  padding-top: 1rem;
		  max-height: 100vh;
		  @include media-breakpoint-up(md) {
			padding-top: 0;
		  }
		}
	  }

	}
  }
}