#nos-marques {
  background: #000;
  padding: 25px 0;
  @include media-breakpoint-up(md) {
	padding: 50px 0;
  }


  .swiper-slide {
	text-align: center;

	img {
	  width: auto;
	  height: 68px;
	}
  }
}