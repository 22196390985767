#qui-sommes-nous {

  @include media-breakpoint-up(md) {
	display: flex;
	margin-bottom: 50px;
  }
  @include media-breakpoint-up(lg) {
	margin: 0 auto;
	padding: 75px 0;
	width: 1000px;
  }


  .content {
	width: 100%;

	background: $gray;
	padding: 65px 20px 20px 20px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include media-breakpoint-up(md) {
	  width: 50%;
	  min-height: 450px;
	  padding: 65px 40px 40px 40px;
	}
	@include media-breakpoint-up(lg) {
	  width: 45%;
	}

	.pneu-logo {
	  &::after {
		left: 0;
		transform: translate(0, 0);
	  }
	}


	a {
	  margin-top: 25px;
	  border: 1px solid $primary;
	  display: inline-block;
	  padding: 5px 10px;
	  background: transparent;
	  color: $primary;
	  font-weight: 700;
	  text-decoration: none;
	}

	.angle {
	  height: 100%;
	  width: auto;
	  position: absolute;
	  top: 0;
	  right: 1px;
	  left: auto;
	  transform: translate(100%, 0);
	  z-index: 2;

	  display: none;
	  @include media-breakpoint-up(md) {
		display: block;
	  }
	}
  }

  .visuel {
	min-height: 450px;
	display: none;
	@include media-breakpoint-up(md) {
	  display: block;
	  width: 50%;
	}
	@include media-breakpoint-up(lg) {
	  width: 65%;
	  padding: 25px 0;
	}


	.inner {
	  height: 100%;
	  background-size: cover;
	  background-position: center;
	  background-image: url("../images/facade-2.jpg");

	}
  }
}