footer {
  background: $primary;
  color: #FFF;
  text-align: center;
  font-size: 0.9rem;
  padding: 30px 0;

  a {
	color: #FFF;
	text-decoration: none;

	&:hover {
	  color: #000;
	  text-decoration: underline;
	}

  }

}