#contact {

  .pneu-logo {
	margin-top: 45px;
	@include media-breakpoint-up(md) {
	  margin-top: 0;
	}
  }

  .content {
	margin: 50px 0;


	@include media-breakpoint-up(md) {
	  display: flex;
	  justify-content: space-between;
	}


	.info {
	  /*width: 350px;
	  flex: 1 1 350px;*/
	  @include media-breakpoint-up(md) {
		margin-right: 50px;
		width: 350px;
		flex: 0 0 350px

	  }
	  @include media-breakpoint-up(md) {
		width: 400px;
		flex: 0 0 400px

	  }

	  h3 {
		font-size: 1.3rem;
		font-weight: 700;
		margin-bottom: .5rem;
	  }

	  a {
		display: inline-block;
		margin-bottom: 1rem;
	  }

	  ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
		  padding-bottom: .4rem;
		}
	  }

	}

	.map {
	  flex: 1;
	  height: 400px;
	  margin-top: 40px;
	  @include media-breakpoint-up(md) {
		margin-top: 0;
		height: auto;
	  }

	  iframe {
		width: 100%;
		height: 100%;
		border: 0;
	  }
	}
  }
}