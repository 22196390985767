#gamme-pneus {
  .pneu-logo {
	margin-top: 25px;
  }

  .content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	//background-image: url("../images/gamme-pneu.jpg");
	background-image: url("../images/visuel-prestation2.png");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: auto 200px;
	padding: 250px 0 40px 0;
	margin-top: 40px;

	@include media-breakpoint-up(lg) {
	  padding: 40px 0;
	  background-position: center;
	  //background-size: auto 280px;
	  background-size: auto 190px;
	}
	@include media-breakpoint-up(xl) {
	  //background-size: auto 320px;
	  background-size: auto 230px;
	}

	@include media-breakpoint-up(xxl) {
	  // background-size: auto 380px;
	  background-size: auto 270px;
	}


	article {
	  width: 100%;
	  display: flex;
	  margin-bottom: 20px;
	  @include media-breakpoint-up(md) {
		width: 45%;
	  }
	  @include media-breakpoint-up(lg) {
		width: 35%;
	  }


	  .icon {
		flex: 0 0 50px;
		width: 50px;
		height: 50px;
		margin-right: 15px;
		position: relative;
		top: -10px;

		&.icon-tourisme {
		  padding: 8px 16px 8px 0;
		}

		svg {
		  fill: $primary;

		  path {
			fill: $primary;
		  }
		}
	  }

	  .content-article {
		h3 {
		  font-size: 1.2rem;
		  font-weight: 700;
		}
	  }

	  &:nth-child(even) {
		text-align: right;

		.icon {
		  order: 2;
		  margin-right: 0;
		  margin-left: 15px;
		}

		.content-article {
		  order: 1;

		}
	  }
	}
  }
}