#visuels {
  max-width: 2200px;
  margin: 0 auto;

  picture, img {
	width: auto;
	height: 500px;
  }

  display: flex;

  > div {
	//	width: ;
	width: 25%;
	padding-bottom: 25%;
	background-size: cover;
	background-position: center;

	&.visuel-1 {
	  background-image: url("../images/visuels/visuel-1.jpg");
	}

	&.visuel-2 {
	  background-image: url("../images/visuels/visuel-21.jpg");
	}

	&.visuel-3 {
	  background-image: url("../images/visuels/visuel-31.jpg");
	}

	&.visuel-4 {
	  background-image: url("../images/visuels/visuel-2.jpg");
	}
  }

}