body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  //background: $primary;
}

.bg-white {
  background: #FFF;
}

img {
  width: 100%;
}

p {
  font-size: 1rem;
}

main {
  padding-top: 50px;
}

.swiper-button-prev, .swiper-button-next {
  &:after {
	display: none;
  }

  svg {
	stroke: #FFF;
	width: 100%;
  }
}

.swiper-button-prev {
  left: 4px;
}

.swiper-button-next {
  right: 4px;
}

.text-primary {
  color: $primary;
}

.text-center {
  text-align: center;
}

/* purgecss start ignore */
.section-padding-top {
  padding-top: 25px;
  @include media-breakpoint-up(md) {
	padding-top: 50px;
  }
  @include media-breakpoint-up(lg) {
	padding-top: 75px;
  }
}

/* purgecss start ignore */
.section-padding-bottom {
  padding-bottom: 25px;
  @include media-breakpoint-up(md) {
	padding-bottom: 50px;
  }
  @include media-breakpoint-up(lg) {
	padding-bottom: 75px;
  }
}

.text-medium {
  font-weight: 500;
}

.overflow-hidden {
  overflow: hidden;
}

.title-fat {
  font-weight: 900;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.title-mini {
  margin-bottom: .4rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.pneu-logo {
  position: relative;
  z-index: 2;

  &:after {
	z-index: 1;
	content: '';
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translate(-50%, 0);
	width: 65px;
	height: 65px;
	background-image: url("../images/pneu-logo.svg");
	background-size: cover;
	background-position: center;
	opacity: 0.15;
  }
}

.btn-hover {
  position: relative;

  span {
	position: relative;
	z-index: 2;
	transition: color .35s ease .15s;
  }

  &::before {
	content: '';
	position: absolute;
	z-index: 1;
	inset: 0;
	background: #000;
	transform: scaleY(0);
	transition: transform .5s ease;
	transform-origin: bottom center;
  }

  &.btn-hover-primary {

	&:hover {
	  span {
		color: #FFF;
	  }
	}

	&::before {
	  background: $primary;
	}
  }

  &.btn-hover-white {
	&:hover {
	  span {
		color: #000;
	  }
	}

	&::before {
	  background: #FFF;
	}
  }


  &:hover {

	&::before {
	  transform-origin: top center;
	  transform: scaleY(1);
	}
  }

}