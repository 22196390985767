header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1);
  padding: 0;
  background: #FFF;
  display: flex;
  height: 50px;
  z-index: 33;
  @include media-breakpoint-up(md) {
	height: 56px;
  }

  .left {
	display: flex;
	align-items: center;
	padding-left: 15px;

	@include media-breakpoint-up(md) {
	  flex: 0 0 180px;
	}

	.logo {
	  img {
		width: auto;
		height: 23px;
		@include media-breakpoint-up(md) {
		  height: 25px;
		}
	  }
	}
  }

  .right {
	margin: 0 7px 0 auto;
	display: flex;
	align-items: center;
	@include media-breakpoint-up(md) {
	  margin-right: 0;
	  //flex: 1;
	}


	.phone {
	  text-decoration: none;
	  font-weight: bold;
	  display: inline-block;
	  margin-right: 10px;

	  @include media-breakpoint-up(md) {
		flex: 0 0 140px;
		width: 140px;
		margin-right: 0;
	  }

	}

	@import './burger.scss';

	nav {
	  position: absolute;
	  top: 50px;
	  left: 0;
	  width: 100%;
	  height: 0;
	  overflow: hidden;
	  transition: height .7s ease;


	  @include media-breakpoint-up(md) {
		position: relative;
		top: 0;
		height: auto;
	  }


	  body.menu-open & {
		height: 169px;
		@include media-breakpoint-up(md) {
		  height: auto;
		}
	  }


	  ul {

		padding: 0;
		margin: 0;
		list-style: none;

		@include media-breakpoint-up(md) {
		  margin-left: auto;
		  display: flex;
		  justify-content: flex-end;
		}

		li {

		  a {
			display: inline-block;
			padding: 15px;
			text-decoration: none;
			color: #FFF;
			width: 100%;
			font-size: 1.1rem;
			text-align: center;
			font-weight: bold;
			transition: backgroundColor .5s ease;

		  }

		  &:nth-child(1) {
			background: $primary-dark;
		  }

		  &:nth-child(2) {
			background: $primary;
		  }

		  &:hover {
			background: #000;
		  }


		}


	  }
	}
  }
}